<template>
  <div class="main-wrapper">
    <Toast />
    <div class="main-container">
      <div class="header-section">
        <h1>PlanCheck</h1>
      </div>
      <div class="grid-section">
        <div class="camera-controls"></div>
        <div class="video-feeds">
          <div class="camera-feed">
            <Card>
              <template #title>Live Camera Feed</template>
              <template #content
                ><video
                  ref="videoElement"
                  autoplay
                  playsinline
                  class="video-element"
                ></video
              ></template>
            </Card>
          </div>
          <div class="current-image">
            <Card>
              <template #title>Image in Analysis</template>
              <template #content>
                <img
                  v-if="capturedImage"
                  :src="capturedImage"
                  alt="Captured Image"
                  class="processed-image"
                />
                <div v-else class="placeholder">No image</div>
              </template>
            </Card>
          </div>
        </div>
        <div class="camera-buttons">
          <Toolbar>
            <template #start>
              <div class="flex items-center gap-2">
                <Button
                  v-tooltip="'Start Camera'"
                  label="Start Camera"
                  icon="pi pi-video"
                  @click="startCamera"
                  class="p-button-success"
                  :disabled="isCameraActive"
                />
                <Button
                  v-tooltip="'Stops camera and analysis'"
                  label="Stop"
                  icon="pi pi-stop"
                  @click="stopCamera"
                  class="p-button-danger"
                  :disabled="!isCameraActive"
                />
                <Button
                  v-if="cameraOptions?.length > 1"
                  label="Select Camera"
                  icon="pi pi-camera"
                  @click="showCameraSelector"
                  class="p-button-help"
                  :disabled="isCameraActive"
                />
                <Button
                  icon="pi pi-camera"
                  v-tooltip="'Change Camera Facing'"
                  @click="toggleCameraFacing"
                  class="p-button-help"
                />
              </div>
            </template>

            <template #end>
              <Button
                v-tooltip="'Capture & Analyze'"
                label="Capture & Analyze"
                icon="pi pi-check-square"
                @click="captureAndAnalyzeImage"
                class="p-button-info"
                :disabled="!isCameraActive || isContinuousActive"
              />
              <Button
                v-tooltip="'Continuous Analysis'"
                label="Continuous Analysis"
                icon="pi pi-play-circle"
                @click="toggleContinuousAnalysis"
                :class="{
                  'p-button-success': isContinuousActive,
                  'p-button-warning': !isContinuousActive,
                }"
                :disabled="!isCameraActive || isContinuousActive"
              />
              <Button
                :label="enforcePlanOrder ? 'Enforcing Order' : 'Ignoring Order'"
                icon="pi pi-sort-amount-down-alt"
                :class="{
                  'p-button-success': enforcePlanOrder,
                  'p-button-warning': !enforcePlanOrder,
                }"
                @click="toggleEnforcePlanOrder"
              />
              <Button
                icon="pi pi-key"
                class="api-key-button"
                @click="showApiKeyModal"
              />
            </template>
          </Toolbar>
        </div>
        <div class="plan-input">
          <h2>The Plan</h2>
          <TextArea
            v-show="editingEnabled"
            placeholder="Type your plan here..."
            v-model="planTextModel"
            class="plan-text-area"
            ref="planTextModelArea"
          ></TextArea>
          <div
            v-show="!editingEnabled"
            class="plan-display"
            ref="planDisplayArea"
          >
            <div
              v-for="(line, index) in lineByLinePlan"
              :key="index"
              :class="{ 'completed-step': line.isCompleted }"
              :id="'plan-line-' + index"
            >
              {{ line.text }}
            </div>
          </div>
          <Toolbar>
            <template #start>
              <div class="flex items-center gap-4">
                <Button
                  label="Save Plan"
                  @click="savePlan"
                  :disabled="!isTextChanged"
                />
                <Button
                  label="Clear Completed Actions"
                  @click="clearCompletedActions"
                  class="p-button-danger"
                  :disabled="shouldDisableClearActions"
                />
              </div>
            </template>
          </Toolbar>
        </div>
        <div class="analysis-section" ref="analysisSection">
          <Card>
            <template #title>Debug</template>
            <template #content>
              <div v-if="isLoading" class="loading-section">
                <span>Loading...</span>
              </div>
              <div v-if="analysis">
                <p>{{ analysis }}</p>
              </div>
              <div v-if="!analysis && !isLoading" class="placeholder">
                Analysis will appear here...
              </div>
            </template>
          </Card>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="isCameraSelectorVisible"
    style="width: 300px"
    header="Select Camera"
  >
    <Dropdown
      :options="cameraOptions"
      optionLabel="label"
      placeholder="Select a Camera"
      v-model="selectedCamera"
      @change="changeCamera"
    />
  </Dialog>
  <Dialog
    v-model:visible="isApiKeyModalVisible"
    header="Enter OpenAI API Key"
    height="700px"
    :style="{ padding: '20px' }"
    :closable="true"
    :showCloseIcon="true"
  >
    <div style="margin-bottom: 1em">
      <InputText v-model="apiKey" placeholder="Paste Key Here" />
    </div>
    <Button label="Save" @click="saveApiKey" class="p-button-success" />
  </Dialog>
</template>

<script setup>
import { ref, onMounted, computed, nextTick, onUnmounted } from "vue";
import { useToast } from "primevue/usetoast";
import Button from "primevue/button";
import Card from "primevue/card";
import Dropdown from "primevue/dropdown";
import Toolbar from "primevue/toolbar";
import Toast from "primevue/toast";
import TextArea from "primevue/textarea";
import Dialog from "primevue/dialog";
import defaultPlan from "@/assets/default_plan.md?raw";
import InputText from "primevue/inputtext";

const toast = useToast();
const videoElement = ref(null);
const capturedImage = ref("");
const cameraOptions = ref([]);
const selectedCamera = ref(null);
const analysis = ref("");
const planTextModel = ref(localStorage.getItem("planTextModel") || defaultPlan);
const originalPlanText = ref(planTextModel.value);
let rawPlanActions = ref(localStorage.getItem("planActions"));
const planActionsModel = ref({});
const analysisSection = ref(null);
const isLoading = ref(false);
const isCameraActive = ref(false);
const isContinuousActive = ref(false);
const isApiKeyModalVisible = ref(false);
const enforcePlanOrder = ref(false);
const cameraFacing = ref("environment");
const apiKey = ref(localStorage.getItem("openaiApiKey"));
let stream = null;

onMounted(async () => {
  if (!localStorage.getItem("planActions")) {
    localStorage.setItem("planActions", "{}");
    rawPlanActions.value = "{}";
  }
  planActionsModel.value = JSON.parse(rawPlanActions.value);
  const devices = await navigator.mediaDevices.enumerateDevices();
  console.debug(`📸 Found devices`, devices);
  let foundCameras = 0;
  cameraOptions.value = devices
    .filter((device) => device.kind === "videoinput")
    .map((device) => {
      const label = device.label.split(" ")[0] + " " + ++foundCameras;
      return { label, value: device.deviceId };
    });
  selectedCamera.value = cameraOptions.value[0];
  document.addEventListener("click", checkForEditModeToggle);
  document.addEventListener("touchstart", checkForEditModeToggle);
  if (!apiKey.value) {
    isApiKeyModalVisible.value = true;
  }
});

onUnmounted(() => {
  document.removeEventListener("click", checkForEditModeToggle);
  document.removeEventListener("touchstart", checkForEditModeToggle);
});

const isCameraSelectorVisible = ref(false);
const showCameraSelector = () => {
  isCameraSelectorVisible.value = true;
};

const CONTINUOUS_IMAGE_ANALYSIS_INTERVAL = 2000;

const imageAnalysisInterval = ref(null);

const toggleContinuousAnalysis = () => {
  isContinuousActive.value = !isContinuousActive.value;
  // Start continuous analysis
  if (isContinuousActive.value) {
    captureAndAnalyzeImage();
    imageAnalysisInterval.value = setInterval(
      continuousAnalysisLoop,
      CONTINUOUS_IMAGE_ANALYSIS_INTERVAL
    );
  } else {
    clearInterval(imageAnalysisInterval.value);
  }
};

const saveApiKey = () => {
  localStorage.setItem("openaiApiKey", apiKey.value);
  isApiKeyModalVisible.value = false;
  toast.add({
    severity: "success",
    summary: "API Key Saved",
    detail: "Your API key has been saved successfully.",
    life: 3000,
  });
};

const showApiKeyModal = () => {
  isApiKeyModalVisible.value = true;
};

const editingEnabled = ref(false);
const planTextModelArea = ref(null);
const planDisplayArea = ref(null);

const checkForEditModeToggle = (event) => {
  const clickedOutsidePlanTextArea =
    !planTextModelArea.value.$el.contains(event.target) &&
    !planDisplayArea.value.contains(event.target);
  if (clickedOutsidePlanTextArea) {
    editingEnabled.value = false;
  } else if (!editingEnabled.value) {
    editingEnabled.value = true;
    planTextModelArea.value.$el.focus();
  }
};

const lineByLinePlan = computed(() =>
  planTextModel.value.split("\n").map((text, index) => {
    return {
      text,
      id: index,
      isCompleted: isStepCompleted(index),
    };
  })
);

function isStepCompleted(index) {
  return planActionsModel.value[index] !== undefined;
}

const isTextChanged = computed(
  () => originalPlanText.value !== planTextModel.value
);

const shouldDisableClearActions = computed(
  () => planActionsModel.value.length === 0
);

const changeCamera = async () => {
  if (stream) {
    stopStreamingMedia();
  }
  startCamera();
};

const savePlan = () => {
  localStorage.setItem("planTextModel", planTextModel.value);
  originalPlanText.value = planTextModel.value;
  toast.add({
    severity: "success",
    summary: "Plan Saved",
    detail: "Your plan has been saved successfully.",
    life: 3000,
  });
};

function clearCompletedActions() {
  planActionsModel.value = [];
  rawPlanActions.value = JSON.stringify(planActionsModel.value);
  localStorage.setItem("planActions", rawPlanActions.value);
}

function toggleEnforcePlanOrder() {
  enforcePlanOrder.value = !enforcePlanOrder.value;
}

function toggleCameraFacing() {
  cameraFacing.value = cameraFacing.value === "user" ? "environment" : "user";
  stopCamera();
  startCamera();
}

const startCamera = async () => {
  try {
    const constraints = { video: { deviceId: selectedCamera.value, facingMode: cameraFacing.value } };
    stream = await navigator.mediaDevices.getUserMedia(constraints);
    videoElement.value.srcObject = stream;
    isCameraActive.value = true;
    toast.add({ severity: "success", summary: "Camera Started", life: 1000 });
  } catch (error) {
    console.error("Error starting camera:", error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: error.message,
      life: 1000,
    });
  }
};

const stopCamera = () => {
  clearInterval(imageAnalysisInterval.value);
  stopStreamingMedia();
  isContinuousActive.value = false;
  isLoading.value = false;
  toast.add({
    severity: "success",
    summary: "Camera & Analysis Stopped",
    life: 1000,
  });
};

const stopStreamingMedia = () => {
  if (stream) {
    stream.getTracks().forEach((track) => track.stop());
    isCameraActive.value = false;
  }
};

const continuousAnalysisLoop = () => {
  if (isContinuousActive.value) {
    captureAndAnalyzeImage();
  }
};

const captureAndAnalyzeImage = () => {
  if (isLoading.value) return;
  isLoading.value = true;
  const canvas = document.createElement("canvas");
  canvas.width = videoElement.value.videoWidth;
  canvas.height = videoElement.value.videoHeight;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(videoElement.value, 0, 0, canvas.width, canvas.height);
  capturedImage.value = canvas.toDataURL("image/png");
  sendImageToOpenAI();
};

function processPlanAction(
  
action) {
  try {
    const parsedAction = JSON.parse(action);
    if (parsedAction.error) {
      throw new Error(parsedAction.error);
    } else if (parsedAction.step == null || parsedAction.step == undefined) {
      throw new Error("AI did not provide a step");
    }
    planActionsModel.value[parsedAction.step] = parsedAction;
    rawPlanActions.value = JSON.stringify(planActionsModel.value);
    localStorage.setItem("planActions", rawPlanActions.value);
  } catch (error) {
    const errorMessage = `😢 AI failed: ${error}`;
    console.error(errorMessage);
    analysis.value += `\n${errorMessage}`;
  }
}

const sendImageToOpenAI = async () => {
  const imageBase64 = capturedImage.value;
  const prefix = `This is a job plan. Here's the content of the plan file:\n${planTextModel.value}`;
  const example_json1 = `{ "step": 1, "error": null }"`;
  let example_json2 = `"{ "step": 2, "error": null }"`;
  if (enforcePlanOrder.value) {
    example_json2 = `"{ "step": 2, "error": "The operator has missed a step."`;
  }
  const example_json3 = `"{ "step": null, "error": "Could not determine the step."`;
  const examples = `${example_json1} or ${example_json2} or ${example_json3}`;
  let command = `Based on the image provided, tell me the step number the operator has completed in JSON format as shown below: ${examples}. Be very precise and detailed, and ensure the text exactly matches what's in the image.`;
  if (enforcePlanOrder.value) {
    const currentState = JSON.stringify(lineByLinePlan.value);
    command += `Here is a JSON model of what the user has currently completed: \n${currentState}.\n`;
    command += `If the image provided shows a completed step out of order, raise an error with a message explaining the issue. E.g., if the user does step 2 before step 1 is completed.`;
  } else {
    command += `It's okay if the step is out of order.`;
  }
  const suffix = `${command}\n DO NOT USE NATURAL LANGUAGE. DO NOT USE MARKDOWN. USE RAW JSON FORMAT ONLY.`;
  const prompt = `${prefix}\n${suffix}`;
  console.debug(`💬 Prompt: ${prompt}`); 
  const payload = {
    model: "gpt-4o",
    messages: [
      {
        role: "user",
        content: [
          {
            type: "image_url",
            image_url: { url: imageBase64 },
          },
          {
            type: "text",
            text: prompt,
          },
        ],
      },
    ],
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${apiKey.value}`,
  };

  try {
    toast.add({
      severity: "info",
      summary: "Sending image for analysis",
      life: 1000,
    });
    const response = await fetch(`/openai-api/chat/completions`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    });

    if (!response.ok) throw new Error("Failed to fetch");

    const data = await response.json();
    analysis.value = data.choices[0].message.content;
    processPlanAction(analysis.value);
    toast.add({ severity: "info", summary: "Analysis complete", life: 1000 });
  } catch (error) {
    console.error("Error:", error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to analyze the image.",
      life: 3000,
    });
    analysis.value = "Failed to analyze image";
  } finally {
    isLoading.value = false;
    await nextTick();
    analysisSection.value.scrollIntoView({ behavior: "smooth" });
  }
};
</script>

<style scoped>
.main-wrapper {
  padding: 5px;
}

.header-section {
  margin-bottom: 5px;
}

.grid-section {
  display: grid;
  gap: 5px;
}

.video-feeds {
  display: flex;
  gap: 10px;
  width: 100%;
}

.camera-feed,
.current-image {
  flex: 1;
}

.processed-image,
.video-element {
  width: 100%;
  height: 100%;
}

.camera-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.placeholder {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #666;
}

.plan-text-area {
  margin-bottom: 20px;
  width: 100%;
  min-height: 200px;
}

.loading-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 20px;
}

.loading-section span {
  display: inline-block;
  animation: throb 1.5s ease-in-out infinite;
}

.completed-step {
  text-decoration: line-through;
}

.plan-display {
  white-space: pre-wrap;
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f9f9f9;
}

@keyframes throb {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}
</style>
